.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tone-button {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  font-size: 1.5em;
  color: #fff;
  background-color: #007bff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0,0,0,.3);
  transition: background-color 0.3s, transform 0.3s;

  /* Remove default mobile tap highlight */
  -webkit-tap-highlight-color: transparent;

  /* Prevent browsers from showing a focus outline */
  outline: none;

  /* Remove browser click effect */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Prevent Safari from adding its own styling */
  -webkit-appearance: none;
  appearance: none;
}

.tone-button:hover, .tone-button:focus {
  background-color: #0056b3;
  transform: scale(1.05);
}

.tone-button:active {
  background-color: #004085;
  transform: scale(0.95);
}

/* Responsive styles */
@media (max-width: 768px) {
  .tone-button {
    width: 150px;
    height: 150px;
    font-size: 1.2em;
  }
}

/* Apply to all elements to prevent browser styles from affecting interaction */
* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

